.my-masonry-grid {
    display: -webkit-box;
    /* Not needed if autoprefixing */
    display: -ms-flexbox;
    /* Not needed if autoprefixing */
    display: flex;
    gap: 20px;
    /* margin-left: -30px; */
    /* gutter size offset */
    width: 100%;
}

.my-masonry-grid_column {
    background-clip: padding-box;
}


/* Style your items */

.my-masonry-grid_column>div {
    /* change div to reference your elements you put in <Masonry> */
    /* background: grey; */
    margin-bottom: 20px;
}