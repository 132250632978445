.fc [role='grid'] {
    border: 1px solid #cecece;
    border-radius: 20px;
    background-color: white;
    overflow: hidden;
}

.fc .fc-scrollgrid-section>* {
    border: none;
}

.fc-daygrid-day-top {
    justify-content: center;
    font-weight: bold;
}

.fc-day-today {
    background-color: #fff !important;
}

.fc-daygrid-day-number {
    font-weight: bold;
    text-align: center;
    width: 25px;
    height: 25px;
    font-size: 12px;
    margin-top: 3px;
}

.fc-day-today .fc-daygrid-day-number {
    color: white;
    border-radius: 100%;
    background-color: #FF007A;
}

.fc-col-header-cell {
    height: 50px;
    align-content: center;
    font-weight: bold;
}

.fc-col-header-cell:last-child {
    border-right: none;
}

.fc-daygrid-day {
    height: 150px;
}

.fc-daygrid-day:hover {
    cursor: copy
}

.today {
    color: #ff007a;
}