.tiptap {
  height: 100%;
  overflow: visible;

  padding: 10px;
  outline: none;
  &:active {
    border-color: none;
  }
  cursor: text;
  white-space: pre-wrap;
}

.bubble-menu {
  display: flex;
  background-color: #0d0d0d;
  padding: 0.2rem;
  border-radius: 0.5rem;
}

.bubble-menu button {
  cursor: pointer;
  border: none;
  background: none;
  color: #fff;
  font-size: 0.85rem;
  font-weight: 500;
  padding: 0 0.2rem;
  opacity: 0.6;

  &:hover,
  &.is-active {
    opacity: 1;
  }
}

.mention {
  border: 0.5px solid #000;
  border-radius: 0.4rem;
  padding: 0.15rem 0.3rem;
  box-decoration-break: clone;
}

.tiptap-p {
  margin: 0;
}

.tiptap p.is-editor-empty:first-child::before {
  color: #adb5bd;
  content: attr(data-placeholder);
  float: left;
  pointer-events: none;
}

.collaboration-cursor__caret {
  position: relative;
  border-left: 2px solid; /* The actual color will be set dynamically */
  height: 1em; /* Adjusted based on your line-height */
  transform: translateY(0.2em); /* Compensate for the height adjustment */
}

.collaboration-cursor__label {
  position: absolute;
  left: -50%; /* Center the label over the caret */
  bottom: 100%; /* Position above the caret */
  background-color: inherit; /* Use the same color as the caret */
  color: #fff; /* Text color, can be changed as needed */
  font-size: 0.75em; /* Smaller font size for the label */
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 4px 4px 4px 0px;
  white-space: nowrap; /* Prevents the text from wrapping */
  transform: translateX(-2px);
  user-select: none; /* Prevent text selection */
  opacity: 0;
  transition: opacity 0.1s; /* Add transition property */
}

.collaboration-cursor__caret:hover .collaboration-cursor__label {
  opacity: 1;
}

.collaboration-cursor__caret:not(:hover) .collaboration-cursor__label {
  transition-delay: 1s; /* Delay when hiding the label */
}
